.paginate {
    display: flex;
    justify-content: space-between;
    list-style: none;
    cursor: pointer;
}

.paginate a {
    padding: 10px;
    color: #000000 !important;
    margin: 0 0.5rem;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
}

.paginate__link {
    font-weight: bold;
}

.paginate__link--active a {
    font-weight: bold;
}

.paginate__link--disabled a {
    color: rgb(198, 197, 202) !important;
    border: unset !important;
    background-color: unset !important;
}

.paginate__end-link {
    border: unset !important;
    background-color: unset;
}
